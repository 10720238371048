import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import axios from "axios";
import { Table, Button, Modal } from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
import HandleModal from "../constants/helper";
import FinancialDetailModal from "../Modals/FinancialDetailModal";
const FinancialLoanListing = () => {
  const [financialLoan, setFinancialLoan] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { hasMorePages, currentPage, fetchpreviousPageData, fetchNextPageData } = HandleModal()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.yoonetinsurance.com/api/insurance/get-financial-list?page=${currentPage}&limit=10"
        );
        setFinancialLoan(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      }
    };
    fetchData();
  }, [currentPage]);

  const handleShowUserInfo = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateStatus = async (user, status) => {
    try {
      await axios.post(
        `https://api.yoonetinsurance.com/api/insurance/update-financial-status?id=${user._id}`,
        { status ,userId:user?.userId?._id}
      );
      const updatedFinancialLoan = financialLoan.map((item) =>
        item.id === user.id ? { ...item, status } : item
      );
      setFinancialLoan(updatedFinancialLoan);
      window.location.reload()
    } catch (error) {
      console.error("Error updating financial status:", error);
    }
  };
  return (
    <div>
      {showModal &&
        <FinancialDetailModal showDetailsModal={showModal}
          close={() => { handleCloseModal() }}
          selectedUser={selectedUser} />
      }
      <div className="header-container">
        <SidebarComponent />
        <div className="user-container">
          <h1>Finance Loans Request</h1>
          <div className="user-table-data">
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ borderRadius: "5px" }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Financial Loan Type</th>
                  <th style={{ textAlign: "center" }}>Loan Amount</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                  <th style={{ textAlign: "center" }}>View Details</th>
                </tr>
              </thead>
              <tbody>
                {financialLoan.length === 0 ? (
                  <tr>
                    <td  style={{ textAlign: "center" }} colSpan="6">No Finance Request is available now</td>
                  </tr>
                ) : (
                  financialLoan.map((user, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{user?.userId?.name}</td>
                      <td style={{ textAlign: "center" }}>{user?.loanType}</td>
                      <td style={{ textAlign: "center" }}>{user?.loanAmount}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="success"
                          className="mr-2"
                          onClick={() => handleUpdateStatus(user, "approved")}
                        >
                          Approve
                        </Button>
                        &nbsp;
                        <Button
                          variant="danger"
                          onClick={() => handleUpdateStatus(user, "rejected")}
                        >
                          Reject
                        </Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="secondary" style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                          onClick={() => handleShowUserInfo(user)}
                        >
                          <FaInfo/>
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
         
          {!showModal &&
              <div
                className="pagination-controls"
                style={{ textAlign: "right", marginRight: "20px" }}>
                <Button
                  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                  onClick={() => { fetchpreviousPageData() }} disabled={currentPage === 1}>
                  Previous
                </Button>
                &nbsp;
                <Button
                  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                  onClick={() => { fetchNextPageData() }} disabled={!hasMorePages}>
                  Next
                </Button>
              </div>
            }

        </div>
      </div>
     
    </div>
  );
};

export default FinancialLoanListing;
