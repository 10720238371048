import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import axios from "axios";
import { Table, Button, Modal, Dropdown } from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
import HandleModal from "../constants/helper";
import InsuranceDetailModal from "../Modals/InsuranceDetailModal";
const CarLoanListingComponent = () => {
  const [insuranceLoan, setInsuranceLoan] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { hasMorePages, currentPage, fetchpreviousPageData, fetchNextPageData } = HandleModal()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.yoonetinsurance.com/api/insurance/get-insurance?page=${currentPage}&limit=10`
        );
        setInsuranceLoan(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      }
    };
    fetchData();
  },[currentPage]);

  const handleShowUserInfo = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleUpdateStatus = async (user, status) => {
    try {
      const response = await axios.post(
        `https://api.yoonetinsurance.com/api/insurance/update-insurance-status?id=${user._id}`,
        { status,userId:user?.userId?._id }
      );
      console.log("===========>>>>>response",response)
      const updateInsuranceLoan = insuranceLoan.map((item) =>
        item.id === user.id ? { ...item, status } : item
      );
      setInsuranceLoan(updateInsuranceLoan);
      window.location.reload()
    } catch (error) {
      console.error("Error updating financial status:", error);
    }
  };
  return (
    <>
     {showModal &&
        <InsuranceDetailModal showDetailsModal={showModal}
          close={() => { setShowModal(false) }}
          selectedUser={selectedUser} />
     }
    <div>
      <div className="header-container">
        <SidebarComponent />
        <div className="user-container">
          <h1>Insurance Loans Request </h1>
          <div className="user-table-data">
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ borderRadius: "5px" }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Mobile Number</th>
                  <th style={{ textAlign: "center" }}>Loan Term</th>
                  <th style={{ textAlign: "center" }}>Vehicle Value</th>
                  <th style={{ textAlign: "center" }}>Approved/Reject</th>
                  <th style={{ textAlign: "center" }}>View Details</th>
                </tr>
              </thead>
              <tbody>
              {insuranceLoan.length === 0 ? (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan="7">No Insurance Loan Request is available now</td>
                  </tr>
                ):(
                insuranceLoan.map((user, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>{user?.userId?.name}</td>
                    <td style={{ textAlign: "center" }}>{user?.userId?.phoneNumber}</td>
                    <td style={{ textAlign: "center" }}>{user?.loanTerm}</td>
                    <td style={{ textAlign: "center" }}>{user?.vehicleValue}</td>
                    <td style={{ textAlign: "center" }}>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="success"
                          className="mr-2"
                          onClick={() => handleUpdateStatus(user, "approved")}
                        >
                          Approve
                        </Button>
                        &nbsp;
                        <Button
                          variant="danger"
                          onClick={() => handleUpdateStatus(user, "rejected")}
                        >
                          Reject
                        </Button>
                      </td>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                        variant="secondary" 
                        onClick={() => handleShowUserInfo(user)}
                      >
                        <FaInfo />
                      </Button>
                    </td>
                  </tr>
                )))}
              </tbody>
            </Table>
          </div>
          {!showModal &&
              <div
                className="pagination-controls"
                style={{ textAlign: "right", marginRight: "20px" }}>
                <Button
                  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                  onClick={() => { fetchpreviousPageData() }} disabled={currentPage === 1}>
                  Previous
                </Button>
                &nbsp;
                <Button
                  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                  onClick={() => { fetchNextPageData() }} disabled={!hasMorePages}>
                  Next
                </Button>
              </div>
            }
        </div>
      </div>
      
    </div>
    </>
  );
};

export default CarLoanListingComponent;
