import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import axios from "axios";
import { Table, Button, Modal } from "react-bootstrap";
import ClaimLodgeDetailsModals from "../Modals/claimLodgeDetails";
import HandleModal from "../constants/helper";
import { FaInfo } from "react-icons/fa";
const ApprovedClaimLodgeListing = () => {
  const [claimData, setClaimData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const {
    hasMorePages,
    currentPage,
    fetchpreviousPageData,
    fetchNextPageData,
  } = HandleModal();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.yoonetinsurance.com/api/insurance/get-approved-claims?page=${currentPage}&limit=10`
        );
        setClaimData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      }
    };
    fetchData();
  }, []);

  const handleShowUserInfo = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  
  return (
    <>
      {showModal && (
        <ClaimLodgeDetailsModals
          showDetailsModal={showModal}
          close={() => {
            setShowModal(false);
          }}
          selectedUser={selectedUser}
        />
      )}
      <div>
        <div className="header-container">
          <SidebarComponent />
          <div className="user-container">
            <h1>Claims List</h1>
            <div className="user-table-data">
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{ borderRadius: "5px" }}
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} >S.NO.</th>
                    <th style={{ textAlign: "center" }} >Name</th>
                    <th style={{ textAlign: "center" }} >Claim Name</th>
                    <th style={{ textAlign: "center" }} >Description </th>
                    <th style={{ textAlign: "center" }} >Claiming</th>
                    <th style={{ textAlign: "center" }} >Status</th>
                    <th style={{ textAlign: "center" }} >Claim Date</th>
                    <th style={{ textAlign: "center" }} >View Details</th>
                  </tr>
                </thead>
                <tbody>
                {claimData.length === 0 ? (
                  <tr>
                    <td  style={{ textAlign: "center" }} colSpan="9">No Claim Request is available now</td>
                  </tr>
                ):(
                  claimData.map((user, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{user?.userId?.name}</td>
                      <td style={{ textAlign: "center" }}>{user?.claimName}</td>
                      <td style={{ textAlign: "center" }}>{user?.claimDescription.slice(0, 10)}...</td>
                      <td style={{ textAlign: "center" }}>{user?.claiming.slice(0, 10)}...</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant={
                            user.status === "rejected" ? "danger" : "success"
                          }
                          style={
                            user.status === "in_progress"
                              ? {
                                  backgroundColor: "#FF914D",
                                  borderColor: "#FF914D",
                                  color: "white",
                                }
                              : {}
                          }
                        >
                          {user.status === "in_progress"
                            ? "In Progress"
                            : user.status === "approved"
                            ? "Approved"
                            : user.status === "rejected"
                            ? "Rejected"
                            : user.status}
                        </Button>
                      </td>
                      <td style={{ textAlign: "center" }}>{user?.claimDate.slice(0, 10)}</td>
                      <td style={{ textAlign: "center" }}>
                      <Button style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                        variant="secondary"
                        onClick={() => handleShowUserInfo(user)}
                      >
                        <FaInfo/>
                      </Button>
                      </td>
                    </tr>
                  )))}
                </tbody>
              </Table>
            </div>

            {!showModal && (
              <div
                className="pagination-controls"
                style={{ textAlign: "right", marginRight: "20px" }}
              >
                <Button
                  style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                  onClick={() => {
                    fetchpreviousPageData();
                  }}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                &nbsp;
                <Button
                  style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                  onClick={() => {
                    fetchNextPageData();
                  }}
                  disabled={!hasMorePages}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
        {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Claim Lodge Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p>
            <strong>Name :</strong> {selectedUser?.userId?.name}
          </p>
          <p>
            <strong>Mobile Number:</strong> {selectedUser?.userId?.phoneNumber}
          </p>
          <p><strong>Claim Name :</strong> {selectedUser?.claimName}</p>
          <p><strong>Claim Description :</strong> {selectedUser?.claimDescription}</p>
          <p><strong>Claim Date :</strong> {selectedUser?.claimDate.slice(0, 10)}</p>
          <p><strong>Claiming :</strong> {selectedUser?.claiming}</p>
          <p><strong>Culprit Name :</strong> {selectedUser?.culpritName}</p>
          <p><strong>Culprit Driving Licence :</strong> {selectedUser?.culpritDrivingLicence}</p>
          <p><strong>List of Damages: :</strong>{selectedUser?.listDamages}</p>
          
          <p><strong>Evidence Image :</strong></p>
          <div className="drivingLicence">
            <img
              className="documentImage"
              src={selectedUser?.evidencePhotoUrl}
              alt="Evidence Image"
            />
          </div>
          <p><strong>Police Evidence Image :</strong></p>
          <div className="drivingLicence">
            <img
              className="documentImage"
              src={selectedUser?.policeEvidencePhoto}
              alt="Police Evidence"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      </div>
    </>
  );
};

export default ApprovedClaimLodgeListing;
