import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { FaInfo, FaBan, FaMailBulk } from "react-icons/fa";
import AddMember from "./AddMember";
import HandleModal from "../constants/helper";
import Tabs from "../Modals/Tabs";
import UseSession from "../constants/useSession";
import { useNavigate } from "react-router-dom";
import DetailModal from "../Modals/DetailModal";
import ChatHook from "../constants/chatHook";
const UserComponent = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [blockSuccess, setBlockSuccess] = useState(false);
  const { open, setValues, activeTab, handleTabClick, tabData, hasMorePages, currentPage, fetchpreviousPageData, fetchNextPageData } = HandleModal()
  const { mySession } = UseSession()
  const navigate = useNavigate();
  const { senparam } = ChatHook()


  useEffect(() => {
    setNewValue(0)
  }, []);


  const setNewValue = (index) => {
    handleTabClick(index)
  }

  const handleShowDetails = (user) => {
    setSelectedUser(user);
    setShowDetailsModal(true);
  };

  const handleOpenConfirmModal = (user) => {
    setSelectedUser(user);
    setConfirmModal(true);
  };


  const renderImages = (imageUrls) => {
    return imageUrls?.split(",")?.map((url, index) => (
      <img className="userImages" key={index} src={url.trim()} alt={`Image ${index}`} />
    ));
  };
  const handleBlockUser = async () => {
    try {
      const response = await axios.post(
        `https://api.yoonetinsurance.com/api/block-user?id=${selectedUser?._id}`,
        { blockStatus: true }
      );
      setBlockSuccess(true);
    } catch (error) {
      console.error("Error blocking user:", error);
    } finally {
      setConfirmModal(false);
      setFeedbackModal(true);
    }
  };


  const handleAdminBlockUser = async () => {
    try {
      const response = await axios.post(
        `https://api.yoonetinsurance.com/api/admin/block-admin?id=${selectedUser?._id}`,
        { blockStatus: true }
      );
      setBlockSuccess(true);
    } catch (error) {
      console.error("Error blocking user:", error);
    } finally {
      setConfirmModal(false);
      setFeedbackModal(true);
    }
  };


  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModal(false);
    setBlockSuccess(false);
    window.location.reload();
  };

  const handleOpenMessagePopup = (param) => {
    navigate(`/chat_Screen`, { state: { param } });
  };

  const handleCloseMessagePopup = () => {
    setShowMessagePopup(false);
  };






  return (
    <div>
      {showDetailsModal &&
        <DetailModal showDetailsModal={showDetailsModal}
          close={() => { setShowDetailsModal(false) }}
          selectedUser={selectedUser} />
      }

      {open &&
        <AddMember close={() => { setValues() }} />}
      <div className="header-container">
        <SidebarComponent />
        {
          <div className="user-container">
            <div className="search-container">

              <Tabs handleTabClick={(index) => { setNewValue(index) }} activeTab={activeTab} />

              <div className="user-search-container">
                {mySession?.loggedInUser.userType === 'superAdmin' &&
                  <Button variant="primary" onClick={() => { setValues() }}
                    style={{ backgroundColor: '#FF914D', borderWidth: 0, width: 200 }}>
                    Add Member
                  </Button>}

                <input
                  style={{ borderColor: '#FF914D', borderWidth: 1 }}
                  type="text"
                  className="inputTextUserSearchField"
                  placeholder="Search User"
                  name="search"
                />
                <Button variant="primary" style={{ backgroundColor: '#FF914D', borderWidth: 0 }}>
                  <FaSearch />
                </Button>
              </div>

            </div>
            <div className="user-table-data">

              {
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ borderRadius: "5px" }}>
                  <thead>
                    <tr>
                      <th>S.NO.</th>
                      <th style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Image</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Email</th>
                      {activeTab === 0 && <>
                        <th>Gender</th></>}
                      {activeTab === 0 && <th>Info</th>}
                      <th>Block User</th>
                      {activeTab === 0 && <>
                        {mySession?.loggedInUser.userType != 'superAdmin' &&
                          <th>Message</th>
                        }
                      </>
                      }


                    </tr>
                  </thead>

                  {

                    <tbody>
                      {tabData?.map((user, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>
                            <img src={user?.image?.trim()} style={{ height: 35, width: 35, alignSelf: 'center', display: 'flex', borderRadius: 18 }} />
                          </td>
                          <td>{user?.name}</td>
                          <td>{user?.phoneNumber ? user?.phoneNumber : user?.mobileNumber}</td>
                          <td>{user?.email}</td>
                          {activeTab === 0 &&
                            <td align='center'>
                              {user?.gender && (
                                <Button
                                  variant={
                                    user.gender.toLowerCase() === "male"
                                      ? "outline-warning"
                                      : "outline-info"
                                  }
                                >
                                  {user.gender}
                                </Button>
                              )}
                            </td>}

                          {activeTab === 0 && <td align='center' >
                            <Button
                              style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                              variant="primary"
                              onClick={() => handleShowDetails(user)}>
                              <FaInfo />
                            </Button>
                          </td>}


                          <td align='center'>
                            <Button
                              style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                              variant="#FF914D"
                              onClick={() => handleOpenConfirmModal(user)} >
                              <FaBan style={{
                                color: "white",
                              }} />
                            </Button>
                          </td>
                          {activeTab === 0 &&

                            (mySession?.loggedInUser.userType != 'superAdmin' &&
                              <td align='center'>
                                <Button
                                  variant="Light"
                                  style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                                  onClick={() => { handleOpenMessagePopup(user) }}
                                >
                                  <FaMailBulk style={{ color: "white", }} />
                                </Button>

                              </td>)
                          }



                        </tr>
                      ))}
                    </tbody>
                  }



                </Table>}


            </div>


            {!showDetailsModal &&
              <div
                className="pagination-controls"
                style={{ textAlign: "right", marginRight: "20px" }}>
                <Button
                  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                  onClick={() => { fetchpreviousPageData() }} disabled={currentPage === 1}>
                  Previous
                </Button>
                &nbsp;
                <Button
                  style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                  onClick={() => { fetchNextPageData() }} disabled={!hasMorePages}>
                  Next
                </Button>
              </div>
            }



          </div>
        }
      </div>





      <Modal show={confirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to block this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => { activeTab === 0 ? handleBlockUser() : handleAdminBlockUser() }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={feedbackModal} onHide={handleCloseFeedbackModal}>
        <Modal.Body>
          {blockSuccess ? (
            <React.Fragment>
              <p>User Blocked Successfully</p>
              <Button variant="secondary" onClick={handleCloseFeedbackModal}>
                Close
              </Button>
            </React.Fragment>
          ) : (
            <p>Error blocking user. Please try again later.</p>
          )}
        </Modal.Body>
      </Modal>

      {showMessagePopup && (
        <Modal show={showMessagePopup} onHide={handleCloseMessagePopup}>
          <Modal.Header closeButton style={{ backgroundColor: "#FF914D" }}>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>This functionality is in process.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseMessagePopup}
              style={{ backgroundColor: "#FF914D", border: "#FF914D" }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default UserComponent;
